<template>
<div class="card p-3">
  <dl class="m-0" v-if="this.unidadadministrativa_id === '1'">
    <dt>CBU:</dt>
    <dd><b>0440034930000009620686</b></dd>
    <dt>Banco:</dt>
    <dd><b>BANCO HIPOTECARIO S.A.</b></dd>
    <dt>CUIT:</dt>
    <dd><b>30-67508895-7</b></dd>
    <dt>Destinatario:</dt>
    <dd class="m-0"><b>Saed Sede Admin Escuelas Diocesanas Obispado de Santiago del Estero</b></dd>
    <!-- <dt>Alias:</dt>
    <dd>alias.saed</dd> -->
  </dl>
  <dl class="m-0" v-if="this.unidadadministrativa_id === '3'">
    <dt>CBU:</dt>
    <dd><b>0170071820000032312900</b></dd>
    <dt>Banco:</dt>
    <dd><b>BBVA Banco Francés</b></dd>
    <dt>CUIT:</dt>
    <dd><b>30-67508895-7</b></dd>
    <dt>Destinatario:</dt>
    <dd class="m-0"><b>PARROQUIA NUESTRA SE</b></dd>
    <!-- <dt>Alias:</dt>
    <dd>alias.saed</dd> -->
  </dl>
  </div>
</template>

<script>
export default {
  name: "CardTransferenciaCuenta",
  components: {
  },
  data() {
    return {
      unidadadministrativa_id: process.env.VUE_APP_UA
    };
  },
  }
</script>

<style scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}
</style>
