<template>
  <div>
    <b-table
      small
      striped
      hover
      responsive
      :items="listacupones"
      :fields="computedFields"
      :selectable="selectable"
      :select-mode="selectmode"
      @row-selected="onRowSelected"
      :tbody-tr-class="rowClass"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
      <template #cell(FechaInforme)="data">
        {{ data.item.PagoPendiente.FechaInforme | moment("DD-MM-YYYY")}}
      </template>
      <template #cell(TipoPagoString)="data">
        {{ !['Pendiente'].includes(data.item.Estado) ? data.item.TipoPagoString === 'Deposito' ? 'Depósito' : data.item.TipoPagoString : data.item.PagoPendiente.TipoPagoString}}
      </template>
      <template #cell(FechaPago)="data">
        {{ data.item.FechaPago | moment("DD-MM-YYYY")}}
      </template>
      <template #cell(UltimoVencimiento)="data">
        {{ data.item.UltimoVencimiento | moment("DD-MM-YYYY")}}
      </template>
      <template #cell(ImporteInformado)="data">
        {{ data.item.PagoPendiente.ImporteInformado | currency}}
      </template>
      <template #cell(ImportePagado)="data">
        {{ data.item.ImportePagado | currency}}
      </template>
      <template #cell(ImporteVencimiento)="data">
        {{ (data.item.Importe == 0 ? 0.0 : data.item.ImporteVencimiento) | currency}}
      </template>
      <template #cell(Acciones)="data">
        <b-button
          class="btn-sm"
          v-if="(!['Pagado','Parcial','Bonificado'].includes(data.item.Estado)) && (moment(data.item.UltimoVencimiento).isAfter(new Date(Date.now()-86400000), 'day'))"
          @click.once="pagarcupon(data.item)"
          >Ver formas de pago</b-button>
          <b-button class="btn-sm" disabled v-if="!['Pagado','Parcial','Bonificado'].includes(data.item.Estado) && moment(new Date()).isAfter(data.item.UltimoVencimiento, 'day')">Vencido</b-button>
        <b-button
          v-if="(['Pagado','Parcial','Bonificado'].includes(data.item.Estado))"
          v-b-popover.hover.top="'Mostrar comprobante'"
          @click.once="mostrarComprobanteModal(data.item)"
          class="py-1"
          variant="primary">
          <i class="bi bi-receipt"></i>
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="comprobante-modal"
      title="Comprobante"
      cancel-title="Cerrar"
      ok-title="Imprimir"
      @ok="imprimirModal"
      hide-header-close
      >
      <template v-if="this.cupon">
        <div><b>DNI:</b> <span v-text="this.cupon.Alumno.Dni"></span></div>
        <div><b>Nombre:</b> <span v-text="this.cupon.Alumno.DisplayName"></span></div>
        <div><b>Colegio:</b> <span v-text="this.cupon.DisplayUnidadEducativaNivelSinCuise"></span></div>
        <hr>
        <div><b>Nro cupón:</b> <span v-text="this.cupon.Id"></span></div>
        <div><b>Concepto:</b> <span v-text="this.cupon.ConceptoString"></span></div>
        <div><b>Importe pagado:</b> $<span v-text="this.cupon.ImportePagado"></span></div>
        <div><b>Fecha de pago:</b> <span v-text="moment(this.cupon.FechaPago).format('DD-MM-YYYY')"></span></div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
import swalerts from "@/helpers/swalerts";
import moment from 'moment'

const { mapFields } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "TableCupones",
  mixins: [swalerts],
  props: {
      listacupones: Array,
      selectmode: String,
      selectable: Boolean,
      columnas: Array,
      rowClass: String
  },
  watch: {
  },
  computed: {
    ...mapFields(['importe','cuponesseleccionados','estadotransaccion']),
    computedFields() {
      if(this.columnas){
        return this.tablacupones.fields.filter(field => this.columnas.includes(field.key));
      }
      return this.tablacupones.fields;
    }
  },
  components: {

  },
  data() {
    return {
      selected:null,
      cupon:null,
      moment: moment,
      tablacupones: {
        items:[],
        fields: [
          {
            key:'selected',
            label: ' ',
          },
          {
            key: 'ConceptoString',
            label: 'Concepto',
            sortable: true,
            thStyle: {
                maxWidth: '80px',
                minWidth: '80px'
            }
          },
          {
            key: 'Id',
            label: 'Nro Cupón',
            sortable: true,
            thStyle: {
                maxWidth: '70px',
                minWidth: '70px'
            }
          },
          {
            key: 'Estado',
            sortable: false,
          },
          {
            key: 'TipoPagoString',
            label: 'Forma de pago',
            sortable: false,
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
          },
          {
            key: 'FechaPago',
            label: 'Fecha de Pago',
            sortable: true,
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
          },
          {
            key: 'ImportePagado',
            label: 'Importe pagado',
            sortable: true,
            formatter: 'formatterImporte',
            class: 'text-right',
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
            // Variant applies to the whole column, including the header and footer
            //variant: 'danger'
          },
          {
            key: 'FechaInforme',
            label: 'Fecha',
            sortable: true,
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
          },
          {
            key: 'ImporteInformado',
            label: 'Importe',
            sortable: true,
            formatter: 'formatterImporte',
            class: 'text-right'
          },
          {
            key: 'UltimoVencimiento',
            label: 'Vence',
            sortable: true,
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
          },
          {
            key: 'ImporteVencimiento',
            label: 'Importe',
            sortable: true,
            formatter: 'formatterImporte',
            class: 'text-right',
            thStyle: {
                maxWidth: '100px',
                minWidth: '100px'
            }
            // Variant applies to the whole column, including the header and footer
            //variant: 'danger'
          },
          {
            key: 'Acciones',
            label: '',
            thStyle: {
                maxWidth: '30px',
                minWidth: '30px'
            }
            // Variant applies to the whole column, including the header and footer
            //variant: 'danger'
          }
        ],
      },
    };
  },
  methods: {
      formatterImporte(value) {
        return value;
      },
      onRowSelected(items) {
        console.log({items});
        this.selected = items[0];
        this.cuponesseleccionados = items;
        console.log(this.cuponesseleccionados);
        //this.importe = this.cuponesseleccionados.map(c => c.ImporteVencimiento).reduce((a, b) => a + b, 0);
        // if(this.selected){
        //   this.importe = this.selected.ImporteVencimiento;
        //   this.cuponesseleccionados.push(this.selected);
        // }else{
        //   this.importe = 0;
        //   this.cuponesseleccionados = [];
        // }
      },
      async asignarCupones(cupon){
        var arraycupones = [];
        arraycupones.push(cupon);
        this.cuponesseleccionados = arraycupones;
        return;
      },
      pagarcupon(cupon){
        console.log('pagar cupon', cupon);
        //this.estadotransaccion = 'formapago';
        //this.$bvModal.show('modal-formapago');
        this.asignarCupones(cupon).then(() => {
          this.$router.push('formapago');
        });
      },
      mostrarComprobanteModal(cupon){
        console.log('mostrando', cupon);
        this.cupon = cupon;
        this.$bvModal.show('comprobante-modal');
      },
      imprimirModal(bvModalEvt){
        console.log('imprimiendo...');
        bvModalEvt.preventDefault();
        window.print();
      }
  },
  mounted() {
  }
}
</script>

<style>
@media screen {
  /*#printSection {
      display: none;
  }*/
}

@media print {
  body * {
    visibility:hidden;
  }
  #comprobante-modal, #comprobante-modal * {
    visibility:visible;
  }
  #comprobante-modal .modal-footer {
    display: none;
  }
  #comprobante-modal .modal-dialog {
    /*position:absolute;
    left:0;
    top:0;*/
    width: 100%;
    margin: 0;
    height: 100%;
  }
}
</style>