<template>
  <div class="image-modal" v-if="isVisible">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="imageSrc" alt="Modal Image">
      <transition name="fade">
        <div class="fade-out-indicator">Cerrando pronto...</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      imageSrc: '',
      timer: null
    };
  },
  methods: {
    openModal(src) {
      this.imageSrc = src;
      this.isVisible = true;
      this.startTimer();
    },
    closeModal() {
      this.isVisible = false;
      this.imageSrc = '';
      this.clearTimer();
    },
    startTimer() {
      this.clearTimer();
      this.timer = setTimeout(() => {
        this.closeModal();
      }, 10000);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }
  },
  mounted() {
    console.log('ImageModal component mounted');
  }
};
</script>

<style scoped>
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Ensure the content is centered vertically */
  height: auto; /* Ensure the modal content height is auto */
}
.modal-content img {
  width: auto;
  height: 500px; /* Set a fixed height in pixels */
  object-fit: contain;
  display: block;
  margin: auto; /* Ensure the image is centered */
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.fade-out-indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  transition: opacity 0.5s;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
